import React from 'react';

import BenefitsCard from "../cards/BenefitsCard";
import SectionLayoutGrid from "../layouts/SectionLayoutGrid";

import Col from "../styled/grid/Col";
import {Header2} from "../styled/typography/Typography";

const BenefitsSection = ({cards, title}) => {
    return (
        <SectionLayoutGrid id={"benefits_related"} customBackground="middle">
            <Col>
                <Header2 color="white">
                    {title}
                </Header2>
            </Col>
            {cards && cards.map((item) => (
                <BenefitsCard
                    key={item.id}
                    title={item.title}
                    avatar={item.avatar.url}
                />
            ))}
        </SectionLayoutGrid>
    );
};

export default BenefitsSection;