import React from 'react';
import parse from "html-react-parser";

import SectionLayoutGrid from "../layouts/SectionLayoutGrid";
import BenefitsCard from "../cards/BenefitsCard";

import Col from "../styled/grid/Col";
import {Body, Header2} from "../styled/typography/Typography";
import {OnlyDesktop, OnlyMobile} from "../styled/views/MobileOrDesktop";
import {ButtonContainer, ButtonLink} from "../styled/button/ButtonComponents";

const ChallengesSection = ({title, description, cards, button, demo}) => {

    return (
        <SectionLayoutGrid id="challenges-section">
            <Col span={6} grid center>
                <Header2 color="white" marginBottom="normal">
                    {title}
                </Header2>
                {cards &&
                <OnlyMobile>
                    {cards.map((item) => (
                        <BenefitsCard key={item.id} title={item.title} avatar={item.avatar.url}/>))}
                </OnlyMobile>
                }
                {description &&
                <Body color="body-text" marginBottom="small">
                    {parse(description)}
                </Body>
                }
                <ButtonContainer>
                    {button &&
                    <ButtonLink to={button.link} color={demo ? "live-primary" : "primary-brand"}>
                        {button.name}
                    </ButtonLink>
                    }
                </ButtonContainer>
            </Col>
            {cards &&
            <Col span={6} center grid>
                <OnlyDesktop>
                    {cards.map((item) => (
                        <BenefitsCard key={item.id} title={item.title} avatar={item.avatar.url}/>))}
                </OnlyDesktop>
            </Col>
            }
        </SectionLayoutGrid>
    );
};

export default ChallengesSection;