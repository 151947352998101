import React from 'react';
import {graphql} from "gatsby";

import Layout from "../../components/layouts/Layout";
import ChallengesSection from "../../components/sections/ChallengesSection";
import BenefitsSection from "../../components/sections/BenefitsSection";
import RelatedContentSection from "../../components/pages/products/RelatedContentSection";
import DemoSection from "../../components/sections/DemoSection";
import SolutionsHeader from "../../components/pages/solutions/SolutionsHeader";
import HeaderSection from "../../components/sections/HeaderSection";

const PalpTemplate = ({data}) => {

    const {palp_template} = data

    return (
        <Layout lang={palp_template.locale} seo={palp_template.SEO}>
            <HeaderSection
                description={palp_template.hero_section.description}
                button={palp_template.hero_section.button}
                image={palp_template.hero_section.image.url}
                title_color={palp_template.hero_section.title_color}
                title_white={palp_template.hero_section.title_white}
                template
            />
            <ChallengesSection
                title={palp_template.challenges_section.title}
                description={palp_template.challenges_section.description}
                button={palp_template.challenges_section.button}
                cards={palp_template.challenges_section.cards}
                demo
            />
            <SolutionsHeader
                image={palp_template.how_we_help_section.illustration.url}
                description={palp_template.how_we_help_section.description}
                title={palp_template.how_we_help_section.title}
                id={"how-we-help-section"}
                cards={palp_template.how_we_help_section.cards}
                subtitle={palp_template.how_we_help_section.subtitle}
            />
            <BenefitsSection
                title={palp_template.benefits_section.title}
                cards={palp_template.benefits_section.cards}
            />
            <DemoSection
                title={palp_template.webinar_section.title}
                description={palp_template.webinar_section.description}
                button={palp_template.webinar_section.button}
            />
            <RelatedContentSection
                locale={palp_template.locale}
                related1_slug={palp_template.related_section.related1_slug}
                related2_slug={palp_template.related_section.related2_slug}
                related3_slug={palp_template.related_section.related3_slug}
                related4_slug={palp_template.related_section.related4_slug}
            />
        </Layout>
    );
};

export const query = graphql`
   query GetSinglePALPTemplates($locale: String, $slug: String) {
     palp_template: strapiTemplatesPalps(locale: {eq: $locale}, slug: {eq: $slug}) {
        locale
        slug
        benefits_section {
          title
          cards {
            title
            avatar {
              url
            }
          }
        }
        challenges_section {
          title
          description
          cards {
            title
            avatar {
              url
            }
          }
          button {
            link
            name
          }
        }
        hero_section {
          title_white
          title_color
          description
          button {
            name
            link
          }
          image {
            url
          }
        }
        how_we_help_section {
          title
          subtitle
          illustration {
            url
          }
          description
          cards {
            title
            description
          }
        }
        related_section {
          related1_slug
          related2_slug
          related3_slug
        }
        webinar_section {
          title
          description
          button {
            name
            link
          }
        }
        SEO {
             title
             isIndexable
             description
             keywords
             preview {
               url
             }
        }
     }
   }
`


export default PalpTemplate;